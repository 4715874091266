//import { CompressTwoTone } from '@mui/icons-material';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
//import {logout} from './user'

export const getAPI = createAsyncThunk('getAPI', async (param, thunkAPI) => {
    //console.log(param)
    if (param.method === "POST") {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if(param.data.token === "")param.data.token= thunkAPI.getState().user.user.login.token;
        //if(!param.data.token)dispatchEvent(logout)
        //dispatchEvent(logout)
        //console.log(thunkAPI.getState().user.user.login.token)
        const response = await axios({method: 'post', url: "https://d61jy2lzig.execute-api.us-east-1.amazonaws.com/", data: JSON.stringify(param.data), headers});
        //console.log(response)
        return response.data
    } else {
        //console.log(url);
        const response = await axios.get(param.url);
        return response.data;
    }
})

export const apiSlice = createSlice({
    name: 'api',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAPI.pending, (state, action) => {
            if (!state.loading) {
                state.loading = true
            }
            state.error = "";
        })

        builder.addCase(getAPI.fulfilled, (state, action) => {
            if (state.loading) {
                //console.log(action.payload.error);
                if(action.payload.error){
                        state.error = action.payload.error
                }else{
                    state.error = "";
                    state.data = action.payload
                }
                state.loading = false
            }
        })

        builder.addCase(getAPI.rejected, (state, action) => {
            if (state.loading) {
                
                state.loading = false
                state.error = 'Error occured'
            }
        })
    },
})

export default apiSlice.reducer