import *  as React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
//import Chip from '@mui/material/Chip';
import { useMediaQuery } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
//import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
//import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginLeft:20,
        marginTop: theme.spacing(1),
        minWidth: 100,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '2px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 16,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
                marginTop:theme.spacing(0.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#B8B8B8",
        color: theme.palette.common.black,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12
    }
}));

const StickyTableRow = styled(TableRow)(({ theme }) => ({

    backgroundColor: "#f8f8f8",
    color: theme.palette.common.black,
    fontWeight: "bold",
    position: "sticky",
    top: 30,
    zIndex: 100

    //position:"sticky",
    //top:55
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    },
    "&:hover": {
        backgroundColor: "#d8d8d8 !important"
    }
}));

function Row({ row, oC, mS }) {
    //console.log(row);
    //const { row } = rows;
    //const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={row.id}>
            <StyledTableRow style={{ cursor: "pointer" }} onClick={() => { mS([row]) }}>
                <StyledTableCell component="th" scope="row" align="left">
                    {/*<Chip size='small' variant="outlined" color='primary' sx={{ fontWeight: "bold" }} onClick={() => { oC({ id: row.id, assetName: row.assetName, device: row.device }) }} label={row.assetName}></Chip>*/}
                    {<div style={{ display: 'inline-block', marginLeft:-20 }}><LongMenu row={row} oC={oC} /></div>}
                    <Typography style={{ display: 'inline-block', fontSize: "12px" }}>{row.assetName}</Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                    {/*<Chip size='small' variant="outlined" sx={{ fontWeight: "bold", borderColor: "navy", color: "navy" }} onClick={() => { mS([row]) }} label={row.place}></Chip>*/}
                    {row.place}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row.duration}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row.reportTime}
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}
const ITEM_HEIGHT = 25;

function LongMenu({ row, oC }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon sx={{ fontSize: "16px" }} />
            </IconButton>
            <StyledMenu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 3.5
                    },
                }}
            >
                <MenuItem sx={{ display: 'block',fontSize:"15px", marginBottom:'-1px', minWidth:0, minHeight:0, paddingY:0 }} onClick={(e) => {setAnchorEl(null); oC({ id: row.id, assetName: row.assetName, device: row.device }) }} disableRipple>
                    <TrendingUpIcon />
                    History
                </MenuItem>
                <Divider sx={{ my: 0, height:0 }} />
                <MenuItem sx={{ display: 'block',fontSize:"15px", marginTop:-1, minWidth:0, minHeight:0, paddingY:0.5 }} onClick={(e) => {
                    setAnchorEl(null);
                    navigate("/Asset?id=" + row.assetName + "")
                }} disableRipple>
                    <EditIcon />
                    Edit
                </MenuItem>
            </StyledMenu>
        </div>
    );
}


const getData = (deviceData, search, map2Table) => {

    //if(!search.latest || !Object.keys(search.latest).length)return [{}];
    if (Array.isArray(search) && search.length !== 0) {
        //if(!search[0].latest || !Object.keys(search[0].latest).length)return [{}];
        const groupBY =search.filter((g)=>{ return g.type === "Group";})
        const assetBY =search.filter((g)=>{ return g.type === "Asset";})

        const gData = groupBY.length?deviceData.filter((item) => {
            return groupBY.map((ji)=>{ return ji.id}).includes(item.groupID)
        }):[];
        
        const aData = assetBY.length?deviceData.filter((item) => {
            return assetBY.map((ji)=>{ return ji.id}).includes(item.id)
        }):[];

        return [...aData, ...gData].map((item) => (
            {
                id: item.id,
                assetName: item.label,
                place: item.addr,//(item.latest && item.latest.place && item.latest.place.label) ? item.latest.place.label : item.latest && item.latest.lat && item.latest.lng ? item.latest.lat.toFixed(6) + ", " + item.latest.lng.toFixed(6) : "",
                reportTime: item.latest && item.latest.utc ? moment.unix(item.latest.utc).fromNow(true) : "none",
                duration: item.latest && item.latest.dur ? moment().subtract(item.latest.dur, 'seconds').fromNow(true) : "-",
                device: item.device,
                groupName: item.groupName ? item.groupName : ""
            }));
    }
    else if (Array.isArray(map2Table) && map2Table.length !== 0) {
        const data1 = deviceData.filter((item) => {
            return map2Table.includes(item.id)
        });

        return data1.map((item) => (
            {
                id: item.id,
                assetName: item.label,
                place:item.addr,// (item.latest && item.latest.place && item.latest.place.label) ? item.latest.place.label : item.latest && item.latest.lat && item.latest.lng ? item.latest.lat.toFixed(6) + ", " + item.latest.lng.toFixed(6) : "",
                reportTime: item.latest && item.latest.utc ? moment.unix(item.latest.utc).fromNow(true) : "none",
                duration: item.latest && item.latest.dur ? moment().subtract(item.latest.dur, 'seconds').fromNow(true) : "-",
                device: item.device,
                groupName: item.groupName ? item.groupName : ""
            }));
    }
    else {

        //const dt = deviceData.filter((d)=> {return d.latest && Object.keys(d.latest).length}) ;
        return deviceData.map((item) => ({
            id: item.id,
            assetName: item.label,
            place:item.addr,// (item.latest && item.latest.place && item.latest.place.label) ? item.latest.place.label : item.latest && item.latest.lat && item.latest.lng ? item.latest.lat.toFixed(6) + ", " + item.latest.lng.toFixed(6) : "",
            reportTime: item.latest && item.latest.utc ? moment.unix(item.latest.utc).fromNow(true) : "none",
            duration: item.latest && item.latest.dur ? moment().subtract(item.latest.dur, 'seconds').fromNow(true) : "-",
            device: item.device,
            groupName: item.groupName ? item.groupName : ""
        }));
    }
}


function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    {
      id: 'asset',
      numeric: false,
      disablePadding: true,
      label: 'Asset',
    },
    {
      id: 'place',
      numeric: false,
      disablePadding: false,
      label: 'Place',
    },
    {
      id: 'duration',
      numeric: false,
      disablePadding: false,
      label: 'Duration',
    },
    {
      id: 'reportTime',
      numeric: false,
      disablePadding: false,
      label: 'Check_In',
    }
  ];
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
//const DashTable = React.memo(function DashTable({ search, openChart, refresh, deviceDataList, mapSearch }) {
export default function DashTable({ search, openChart, refresh, deviceDataList, mapSearch, map2Table }) {
    //console.log("deviceDataList",deviceDataList);
    const [deviceData, setDeviceData] = React.useState(deviceDataList);
    const [rows, setRows] = React.useState(getData(deviceData, search, map2Table));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('asset');
    const matches = useMediaQuery('(max-width:600px)');
    //const [group, setGroup] = React.useState(false);

    const handleRequestSort = (event, property) => {
        console.log("here");
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    React.useEffect(() => {
        //setDeviceList(deviceDataList.device.list);
        setDeviceData(deviceDataList);
    }, [refresh, deviceDataList])

    const visibleRows = React.useMemo(
        () =>
          stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, rows],
      );

    React.useEffect(() => {
        //console.log(getData(deviceData, search, map2Table))
        //setRows(getData(deviceData, search, map2Table).sort((a, b) => a.groupName < b.groupName ? 1 : -1));
        setRows(stableSort(getData(deviceData, search, map2Table).sort((a, b) => a.groupName < b.groupName ? 1 : -1), getComparator(order, orderBy)).slice(),[order, orderBy])

    }, [deviceData, search, map2Table,order, orderBy])

    return (
        <>
        {/*<TableContainer sx={{ height: (matches ? "calc(40dvh - 31px)" : "calc(100dvh - 64px)"), width: (matches ? "100vw" : "100%"), marginTop: (matches ? "0px" : "0px") }} component={Paper}>
            <Table stickyHeader size={"small"} sx={{ minWidth: 470 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        
                        <StyledTableCell align='left'>Asset ({rows.length})</StyledTableCell>
                        <StyledTableCell align='left'>Place</StyledTableCell>
                        <StyledTableCell align='left'>Duration</StyledTableCell>
                        <StyledTableCell align='left'>Check-In</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i, arr) => (
                            <>
                                {i === 0 || (i !== 0 && row.groupName !== arr[i - 1].groupName) ? <StickyTableRow><TableCell sx={{ fontWeight: "bold", fontSize: "12px", whiteSpace: "nowrap" }}>{(row.groupName) ? row.groupName : "No Group"}</TableCell><TableCell /><TableCell /><TableCell /></StickyTableRow> : <></>}
                                <Row row={row} oC={(data) => { openChart(data) }} mS={(data) => { mapSearch(data) }} key={row.id}></Row>
                            </>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>*/}
        
        <TableContainer  sx={{ height: (matches ? "calc(40dvh - 31px)" : "calc(100dvh - 64px)"), width: (matches ? "100vw" : "100%"), marginTop: (matches ? "0px" : "0px") }} component={Paper}>
          <Table stickyHeader size={"small"} sx={{ minWidth: 550 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
            {visibleRows.map((row, i, arr) => (
                <React.Fragment key={row.id}>
                {i === 0 || (i !== 0 && row.groupName !== arr[i - 1].groupName) ? (
                    <StickyTableRow key={`group-${row.groupName}-${i}`}>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "12px", whiteSpace: "nowrap" }}>
                        {row.groupName || "No Group"}
                    </TableCell>
                    <TableCell /><TableCell /><TableCell />
                    </StickyTableRow>
                ) : null}
                <Row 
                    row={row} 
                    oC={(data) => openChart(data)} 
                    mS={(data) => mapSearch(data)} 
                    key={row.id} 
                />
                </React.Fragment>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
    );
}

//export default DashTable;
