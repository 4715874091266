import React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuItem = ({ title, setTitle, setOpen, open, path, Icon, isMain = false,  onClick }) => {
    const navigate = useNavigate();
    const itemTitle = path.replace(/^\/|\/$/g, '');
    const isActive = itemTitle === title || (isMain && title==='') ;

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(path);
            setTitle(itemTitle);
            setOpen(false);
        }
    };

    return (
        <ListItem key={path} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                onClick={handleClick}
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    backgroundColor: isActive ? "primary.main" : "#FFF",
                    color: isActive ? "#FFF" : "primary.main",
                    '&:hover': {
                        color: "#FFF",
                        background: (theme) => theme.palette.primary.light, // Change background on hover
                        '.MuiSvgIcon-root': {
                            color: '#FFF', // Change icon color on hover
                        },
                    },
                }} 
            >
                <ListItemIcon sx={{
                    minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',
                }}>
                    <Icon sx={{ color: isActive ? "#FFF" : "primary.main" }} />
                </ListItemIcon>
                <ListItemText primary={itemTitle} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    );
};

export default MenuItem;