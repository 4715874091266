import { createTheme } from "@mui/material";

const theme = createTheme(
    {
        palette: {
            hubblePurple: {
                main: '#5A64FF', 
            },
            hubblePurpleLight: {
                main: '#7b83ff', 
            },
            darkPurple: {
                main: '#171442',
            },
            root: {
                color: '#d8d8d8',
            },
            primary: {
                main: "#5A64FF"
            },
            secondary: {
                main: "#171442"
            },
            text: {
                primary: "#000"
            },
        
        },

        components: {
            MuiButton: {
                styleOverrides: {
                    contained: {
                        fontSize: '16sx',
                        fontWeight: '400'
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:nth-of-type(odd)": {
                            backgroundColor: "theme.palette.action.hover", // Use the theme palette
                        },
                        "&:last-child td, &:last-child th": {
                            border: 0,
                        },
                        "&:hover": {
                            backgroundColor: "#d8d8d8 !important", // Inline styling for hover
                        },
                    },
                },
            },
            
    
        }
    }
)

export default theme;