import React, { } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
//import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
//import Switch from '@mui/material/Switch';

//import locactionData from '../JSON/us_states.json';
//import assetsData from '../JSON/assets.json';

//import HomeIcon from '@mui/icons-material/Home';
import DashIcon from '@mui/icons-material/Dashboard';
//import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import LogoutIcon from '@mui/icons-material/Logout';
//import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';

//import SearchIcon from '@mui/icons-material/Search';
//import Autocomplete from '@mui/material/Autocomplete';
//import {InputAdornment } from "@mui/material";

import Chip from '@mui/material/Chip';


//import Home from './home';
import BannerLogo from '../Assets/hubble_logo.svg';
//import Dashboard from './dashboard/dashboard';
//import Assets from './assets';
//import Location from './place/places';
import { logout } from "../store/user"
import { useDispatch, useSelector } from 'react-redux'
//import { isMobile } from 'react-device-detect';
//import Menu from '@mui/material/Menu';
//import Container from '@mui/material/Container';
//import Avatar from '@mui/material/Avatar';
//import Button from '@mui/material/Button';
//import Tooltip from '@mui/material/Tooltip';
//import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
//import TextField from '@mui/material/TextField';
import CreatableSelect from 'react-select/creatable';

import useMediaQuery from '@mui/material/useMediaQuery';
import { getAPI } from "../store/api";
import MenuItem from './navbar/menuItem';


const drawerWidth = 200;


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



/*const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M12 6H12.01M9 20L3 17V4L5 5M9 20L15 17M9 20V14M15 17L21 20V7L19 6M15 17V14M15 6.2C15 7.96731 13.5 9.4 12 11C10.5 9.4 9 7.96731 9 6.2C9 4.43269 10.3431 3 12 3C13.6569 3 15 4.43269 15 6.2Z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#5A64FF',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#5A64FF',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));*/

/*const StyledTextField = styled(TextField)(({ theme }) => ({
    color: "#fff",
    background: "rgba(255, 255, 255, 0.15)",
    borderRadius: "4px",
    width: "100%",
    "& input": {
        color: "#fff !important"
    },
    "& fieldset": {
        borderWidth: "0px",
        "& fieldset:hover, & fieldset:focus, & fieldset:active": {
            borderWidth: "0px"
        },
        "& .MuiInputBase-input": {
            padding: theme.spacing(2, 1, 1, 2),
            transition: theme.transitions.create("width"),
            color: "#fff",
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "12ch",
                "&:focus": {
                    width: "20ch"
                }
            }
        }
    }
}));*/


  

export default function Navbar({listItems, searchData}) {
    //console.log(listItems)
    //console.log("loading navbar");
    const matches = useMediaQuery('(max-width:600px)');
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.user)

    const dispatch = useDispatch()
    //const settings = ['Dashboard', 'Asset', 'Place', 'Logout'];

    const [open, setOpen] = React.useState(false);
    //const [userDetail, setUserDetail] = React.useState({"orgName":"iGPS"});
    //const [search, setSearch] = React.useState("");
    //const [menuPage, setmenuPage] = React.useState("Dashboard");
    const [title, setTitle] = React.useState(useLocation().pathname.replace("/",""));
    const [searchItem, setSearchItem] = React.useState([]);
    const [searchField, setSearchField] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    //const [isLoading, setIsLoading] = React.useState(false);
    //const [options, setOptions] = React.useState(ColourOption);


    const customStyles = {
        control: (base, state) => ({
            ...base,
          background: "#fff",
          // match with the menu
          //borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
          // Overwrittes the different states of border
          //borderColor: state.isFocused ? "yellow" : "green",
          // Removes weird border around container
          //boxShadow: state.isFocused ? null : null,
          "&:hover": {
            // Overwrittes the different states of border
            //borderColor: state.isFocused ? "red" : "blue"
            
          },
          
          minWidth:(matches)?"200px":"400px"
        }),
        menu: base => ({
          ...base,
          // override border radius to match the box
          borderRadius: 0,
          // kill the gap
          marginTop: 0,
          
        }),
        menuList: base => ({
          ...base,
          color:"#000",
          fontSize:"14px",
          //alignItems:"left",
          // kill the white space on first and last option
          padding: 0
        }),
        
      };
      
    
    
    React.useEffect(() => {
        if(listItems && listItems.length){
          

            setSearchItem(listItems)
        }
         //
    },[listItems])

    /*React.useEffect(() => {
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",                
                "user": {
                    "detailUser": {}
                }
            }
        })).then(res => {
            if (res && res.payload && !res.payload.error) {                
                //console.log(res.payload);
                setUserDetail(res.payload.user.detailUser);
            }
        })
    }, [dispatch])*/
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    //const [anchorElUser, setAnchorElUser] = React.useState(null);

    /*const resizeWindow =()=>{
        console.log("isMobile",isMobile);
    }
    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow); 
      }, []);*/


    /*const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };*/

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: (matches) ? 0 : `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: (matches) ? 0 : `calc(${theme.spacing(7)} + 1px)`,
        },
    });

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    /*const searchData = (data) => {
        //console.log("data",data)
        setSearchItem(data);
    }*/
    //const userDetails = (data)=>{
        //console.log(data);
        //setUserDetail(data);
    //}
    
    

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    /*const handleCreate = (inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
          const newOption = createOption(inputValue);
          setIsLoading(false);
          setOptions((prev) => [...prev, newOption]);
          setSearchField(newOption);
        }, 1000);
      };*/
      
      
    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" open={open} >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 0,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/*<Typography variant="h6" noWrap component="div" style={{marginLeft:10}}>
                        {title?title:"Dashboard"}
                    </Typography>*/}
                    <Box sx={{ flex: 1 }} />
                    {(title === "Dashboard" || title === "")?<CreatableSelect styles={customStyles}
                        onBlur={()=>{setFocus(false)}}
                        onFocus={()=>{setFocus(true)}}
                        value={focus?searchField:(!focus && searchField.length <=1 )?searchField:(searchField && searchField.length)?[{"label": searchField.length+" Item(s)","value":""}]:""}
                        placeholder="Search..." onChange={(data)=>{if(data.length === 0){searchData([])} setSearchField(data);searchData(data)}} isMulti options={searchItem}/>:<></>}

                    <Box sx={{ flex: 1 }} />
                    <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                    {/*userDetail&&userDetail.orgName?<Chip variant="filled" sx={{color:"#fff", background:"grey"}} label={"iGPS"}></Chip>:<></>*/}
                    <Chip variant="filled" sx={{color:"#fff", background:(theme)=>theme.palette.hubblePurpleLight.main}} label={(user && user.detailUser && user.detailUser.orgName)?user.detailUser.orgName:"Company"}></Chip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} >
                <DrawerHeader>
                    <img alt={"HubbleNetwork"} style={{ padding: 7 }} src={BannerLogo} height={42} padding={5}></img>
                    {/*<Typography variant="h5" sx={{ pr: 4, pl: 1 }} noWrap component="div">
                        HubbleNetwork
                    </Typography>*/}
                    <IconButton onClick={handleDrawerClose}>

                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <MenuItem 
                        title={title} 
                        setTitle={setTitle} 
                        setOpen={setOpen} 
                        open={open} 
                        path="/Dashboard" 
                        Icon={DashIcon}
                        isMain={true}
                    />
                    <MenuItem 
                        title={title} 
                        setTitle={setTitle} 
                        setOpen={setOpen} 
                        open={open} 
                        path="/Asset" 
                        Icon={SettingsRemoteIcon} 
                    />
                </List>
                <Divider />
                <List>
                <MenuItem 
                        title={title} 
                        setTitle={setTitle} 
                        setOpen={setOpen} 
                        open={open} 
                        path="Logout" 
                        Icon={LogoutIcon}
                        onClick={() => {
                            dispatch(getAPI({
                                "method": "POST",
                                "data": {
                                    "token": "",                
                                    "logout": {}
                                }
                            })).then(res => {
                                dispatch(logout())
                                navigate("/")
                            })
                        }} 
                    />
                </List>
            </Drawer>
            {/*<Dashboard search={search} />*/}
            
        </>
    );
}