import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    gridClasses,
    GridActionsCellItem,
    GridToolbar,
    GridRowEditStopReasons
} from '@mui/x-data-grid';
import Navbar from '../navbar';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAPI } from "../../store/api";
import { useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FileDownload } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { IconButton, Stack } from '@mui/material';
import "./styles.css";
import dayjs from 'dayjs';

const StripedGrid = styled(DataGrid)(({ theme }) => ({ 
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.action.hover,
        '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.action.hover,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: "#d8d8d8 !important",
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: "#e8e8e8",
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    '.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus': {
        outline: 'none !important',
    }
}));

function AutoComp(props) {
    const { option, value, api, id, field, setFields, hasLabel} = props;
    const handleValueChange = (newValue, val) => {
        setFields((oldField)=>{ return {...oldField, [field]:option.find((o) => o[hasLabel?"label":"id"] === val)?.id}})
        api.setEditCellValue({
            id, field,
            value: option.find((o) => o[hasLabel?"label":"id"] === val)?.id//typeof newValue === 'string' ? value : newValue?.value || 'chatty',
        });
    };
    return (
        <Autocomplete
            //disablePortal
            size="medium"
            id={id}
            options={option.map((opt) => opt[hasLabel?"label":"id"])}
            sx={{ flex: 1}}
            freeSolo
            value={option.find((o) => o[hasLabel?"value":"id"] === value)?.[hasLabel?"label":"id"] || ''}
            onChange={handleValueChange}
            renderInput={(params) => <TextField {...params} />}
        />)
}

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        
        const id = "new";//randomId();

        setRows((oldRows) => {
            if(oldRows[0] && oldRows[0].isNew && oldRows[0].isNew === true){
                return oldRows;    
            }else{
                const updatedRows = structuredClone(oldRows);
                const newRow = { id, isNew: true };
                updatedRows.unshift(newRow);
                return updatedRows;
            }
            
        });
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <Fab onClick={handleClick} sx={{ position: "fixed", right: 10, bottom: 8 }} size="small" color="primary" aria-label="add">
            <AddIcon />
        </Fab>
    );
}

export default function Assets() {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [snackBar, setSnackBar] = React.useState(false);
    const [devices, setDevices] = React.useState(false);
    const [groups, setGroups] = React.useState(false);
    const [batch, setBatch] = React.useState(false);
    const [batchDialog, setBatchDialog] = React.useState(false);
    const [groupID, setGroupID] = React.useState(false);
    const [changes, setChanges] = React.useState(false);
    //const [filter, setFilter] = React.useState(false);
    const dispatch = useDispatch()
    const [confirm, setConfirm] = React.useState(false);
    const [fields, setFields] = React.useState({
        label:"",
        groupID:"",
        device:""
    });
    const [exportDialog, setExportDialog] = React.useState(false);
    const [downloadLink, setDownloadLink] = React.useState("");
    
     const [fromDate, setFromDate] = React.useState(() => {
        return dayjs().subtract(90, 'days').startOf('day'); // 90 days ago, midnight
    });
    const [toDate, setToDate] = React.useState(() => {
        return dayjs().startOf('day'); // Today, midnight
    });

    const [fromTime, setFromTime] = React.useState(dayjs().format('HH:mm'));
    const [toTime, setToTime] = React.useState('00:00');

    const matches = useMediaQuery('(max-width:600px)');

    React.useEffect(() => {
        if (exportDialog) {
            const now = dayjs();
            const ninetyDaysAgo = now.subtract(90, 'days').startOf('day');
            
            setFromDate(ninetyDaysAgo);
            setToDate(now);
            setToTime(dayjs().format('HH:mm'));
            setFromTime("00:00");
        } }
    ,[exportDialog]);


    React.useEffect(() => {

        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                "asset": {
                    "list": {}
                },
                "device": {
                    "list": {}
                },
                "group": {
                    "list": {}
                }
            }
        })).then(res => {
            if (res && res.payload && !res.payload.error) {
                if (res.payload.asset.list.length) {
                    var grp = JSON.parse(JSON.stringify(res.payload.group.list));
                    for (let j in grp) {
                        grp[j].value = grp[j].id;
                    }
                    setRows(res.payload.asset.list);
                    setDevices([{ "id": "None" }, ...res.payload.device.list]);
                    setGroups([{ "id": " ", "value": " ", "label": "None" }, ...grp]);
                }
            }
        })
    }, [dispatch])

    const multiAssets = () => {
        let asset = {};
        asset["update"] = [];
        for (var k in batch) asset["update"].push({ id: batch[k], "groupID": groupID === " " ? null : groupID });
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                asset
            }
        })).then(res => {
            if (res.payload.asset.update) {
                setSnackBar("Batch operation successfull.")
                setBatchDialog(false);
            }
            else {
                setSnackBar("Error! executing batch.")
            }

            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "token": "",
                    "asset": {
                        "list": {}
                    }
                }
            })).then(res => {
                if (res && res.payload && !res.payload.error) {
                    if (res.payload.asset.list.length) {
                        setRows(res.payload.asset.list);
                    }
                }
            })
        })
    }

    const combineDateAndTime = (date, time) => {
        const [hours, minutes] = time.split(':');
        return dayjs(date).hour(parseInt(hours)).minute(parseInt(minutes)).second(0); // Set the hour, minute, and reset seconds
    };

    const handleFromDateChange = (newValue) => {
        setFromDate(combineDateAndTime(newValue, fromTime));
    };

    const handleFromTimeChange = (event) => {
        const time = event.target.value;
        setFromTime(time);
        setFromDate(combineDateAndTime(fromDate, time));
    };

    const handleToDateChange = (newValue) => {
        setToDate(combineDateAndTime(newValue, toTime));
    };

    const handleToTimeChange = (event) => {
        const time = event.target.value;
        setToTime(time);
        setToDate(combineDateAndTime(toDate, time));
    }

    const downloadData = () => {
        setDownloadLink("");

        let selectedDevices = [...batch];

        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                "assetData": {
                    "export": {
                        "disableConsolidation": true,
                        "id": selectedDevices,
                        "utcStart": Math.floor(fromDate.toDate().getTime() / 1000),
                        "utcEnd": Math.floor(toDate.toDate().getTime() / 1000)
                    }
                }
            }
        })).then(res => {
            try{
                let link = res.payload.assetData.export.exportUrl;
                setDownloadLink(link);

                const anchor = document.createElement('a');
                anchor.href = link;
                anchor.download = '';
                anchor.click();
            } catch (e){
                
                console.log(e);
            }
        })
    }

    const AddEditAsset = (data) => {

        let obj = {}
        var asset = {}
        if (data.id !== "new") {
            for (var i in rows) {
                if (rows[i].id === data.id) {
                    if (rows[i].label !== data.label) {
                        obj.label = data.label;
                    }
                    if (rows[i].device !== data.device) {
                        obj.device = data.device === "None" ? null : data.device;
                    }
                    if (rows[i].groupID !== data.groupID) {
                        obj.groupID = data.groupID === " " ? null : data.groupID;
                    }
                    if (Object.keys(obj).length) obj.id = rows[i].id;
                    break;
                }
            }
        } else {
            if (data.label) obj["label"] = data.label;
            if (data.groupID) obj["groupID"] = data.groupID === " " ? null : data.groupID;
            if (data.device) obj["device"] = data.device === "None" ? null : data.device;
        }

        if (!Object.keys(obj).length) return;


        if (data.id !== "new") {
            asset["update"] = {
                ...obj
            }
        } else {
            asset["add"] = obj;
        }
    
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                asset
            }
        })).then(res => {
            if (res.payload.asset.update) {
                setSnackBar("Asset " + data.label + " Updated.")
            }
            else if (res.payload.asset.add) {
                setSnackBar("New Asset " + data.label + " Added.")
            } else {
                setSnackBar("Error! adding/updating Asset.")
            }

            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "token": "",
                    "asset": {
                        "list": {}
                    }
                }
            })).then(res => {
                if (res && res.payload && !res.payload.error) {
                    if (res.payload.asset.list.length) {
                        setRows(res.payload.asset.list);
                    }
                }
            })
        })
    }

    const handleClose = () => {
        setConfirm(false);
    };

    const handleCloseExportDataDialog = () => {
        setExportDialog(false); 
        setDownloadLink(""); 
    };

    const deleteAsset = React.useCallback(() => {
        var asset = { "list": {} };

        asset["del"] = confirm.map((cnf) => cnf.id);
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                asset
            }
        })).then(res => {
            if (res.payload.asset.del) {
                setRows(res.payload.asset.list);
                setSnackBar("Asset(s) " + confirm.map((cnf) => cnf.label).toString() + " Deleted.")
                setConfirm(false);
            } else {
                setSnackBar("Error! Deleting Asset(s).")
            }
        })
    }, [dispatch, confirm]);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        if(!Object.keys(rowModesModel).length){
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        }else{
            setSnackBar("Already another row is in editing mode.")
        }
    };

    const handleSaveClick = (id) => () => {
    
        const editedRow = rows.find((row) => row.id === id);
        if((fields.label && (fields.label !== editedRow.label)) || (fields.groupID && (fields.groupID !== editedRow.groupID)) || (fields.device && (fields.device !== editedRow.device))){
            setChanges({"id":id, "event":"save"});
        }else{
            setFields({
                label:"",
                groupID:"",
                device:""
            })
            setChanges(false);
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }
        
        //let row = apiRef.current.getRow(id);
        //console.log(row);
        //console.log(rowModesModel);
        //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        
    };

    const handleDeleteClick = () => {
        //console.log("here",batch);
        //console.log(rows.filter((row) => batch.includes(row.id)))
        setConfirm(rows.filter((row) => batch.includes(row.id)));
        //setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        const editRow = rows.find((row) => row.id === id);
        if((fields.label && (fields.label !== editRow.label)) || (fields.groupID && (fields.groupID !== editRow.groupID)) || (fields.device && (fields.device !== editRow.device))){
            setChanges({"id":id, "event":"cancel"});
        }else{
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
            setFields({
                label:"",
                groupID:"",
                device:""
            })
            setChanges(false);
        }
        //return false
        //setChanges(true);
        
        
    };

    const YesEditCancel = ()=>{
        //console.log(changes)
        let id = changes.id;
        if(changes.event === "cancel"){
            //console.log(changes);
            
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
        }else{
            //console.log("here");
            //AddEditAsset(changes.data);
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }
        setChanges(false);
    }
    
    const NoEditCancel = ()=>{
        //console.log(changes)
        let id = changes.id;
        if(changes.event === "cancel"){
            //console.log(changes);
            setChanges(false);
            return false;
            
        }else{
            setChanges(false);
            
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
        }
        //setChanges(false);
    }

    const processRowUpdate = (newRow) => {
    
        if ((newRow.id === "new") && (!newRow.label)) {
            setSnackBar("Asset name is mandetory.")
            return false;
        }

        //setChanges({...changes, "data":newRow});
        AddEditAsset(newRow);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        //console.log(newRowModesModel);
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            field: 'label',
            headerName: 'Label',
            flex: 1,
            minWidth: 120,
            //(matches)?("minWidth:120"):("flex: 1"),

            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderEditCell: (params) => {
                const { value, api, id, field} = params;
                 return(
                    <TextField sx={{flex:1}} onChange={(event)=>{ api.setEditCellValue({id, field, value:event.target.value}); setFields((oldField)=>{ return {...oldField, "label":event.target.value }}) }} value={value}></TextField>
                )
            }
        },
        {
            field: 'groupID',
            headerName: 'Group',
            flex: 1,
            minWidth: 120,
            editable: true,
            //type: 'singleSelect',
            valueOptions: () => groups,
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const option = groups.find((opt) => opt.id === value)
                //console.log(option)
                return option && option.label;
            },
            renderEditCell: (params) => (
                <AutoComp {...params} option={groups} setFields={setFields} hasLabel={true} />
            ),
        },
        {
            field: 'device',
            headerName: 'Device',
            flex: 1,
            sorting: false,
            minWidth: 200,
            editable: true,
            //type: 'singleSelect',
            valueOptions: devices.length ? [...devices.map((m) => m.id)] : [],
            renderEditCell: (params) => (
                <AutoComp {...params} option={devices} setFields={setFields} hasLabel={false}/>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: "80",
            cellClassName: 'actions',
            getActions: ({id}) => {
                
                //console.log(param);

                //const row ="";
                //const id ="";
                
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon color='primary' />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon color='primary' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                    /*, <GridActionsCellItem
                         icon={<DeleteIcon color='primary' />}
                         label="Delete"
                         onClick={handleDeleteClick(id)}
                         color="inherit"
                     />,*/
                ];
            },
        },
    ];

    return (
        <Box sx={{ display: "flex" }}>
            <Navbar />/
            <Box component={"main"} sx={{ flexGrow: 1, overflow: "hidden", marginTop: matches ? 7 : 8 }}>
                <Box
                    sx={{
                        height: matches ? "calc(100dvh - 57px)":"calc(100dvh - 65px)",
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        }
                    }}
                >
                    <StripedGrid
                        //experimentalFeatures={{ ariaV7: true }}
                        sx={{
                            overflowX: "hidden"
                        }}
                        checkboxSelection
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        onCellDoubleClick={(param,e)=> e.stopPropagation()}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setBatch(newRowSelectionModel)
                        }}
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        
                        
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        //hideFooter
                        hideFooterPagination
                        processRowUpdate={processRowUpdate}
                        slots={{ toolbar: GridToolbar }}
                            slotProps={{
                            toolbar: {
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                                showQuickFilter: true,
                                /*quickFilterProps:{
                                    value:filter,
                                    onChange:(val)=>{
                                        setFilter(val.currentTarget.value)
                                    }
                                }*/
                            }
                        }}
                    />
                </Box>
                {confirm ? <Dialog
                    open={true}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Confirm Delete?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure want to delete selected Asset(s) {confirm.map((cnf) => cnf.label).toString()}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteAsset}>Yes</Button>
                        <Button onClick={handleClose}>No</Button>
                    </DialogActions>
                </Dialog> : <></>}
                {changes ? <Dialog
                    open={true}
                    keepMounted
                    //onClose={NoEditCancel}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Confirm!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {changes.event === "cancel"?"Exit Unsaved?":"Save Changes?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={YesEditCancel}>Yes</Button>
                        <Button onClick={NoEditCancel}>No</Button>
                    </DialogActions>
                </Dialog> : <></>}
                {batchDialog ? <Dialog sx={{ flex: 1 }} open={batchDialog} onClose={false}>
                    <DialogTitle>Group Selection</DialogTitle>
                    <DialogContent>
                        <FormControl size='small' sx={{ marginTop: 1, width: 250 }} fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">Group</InputLabel>
                            <Select
                                size='small'
                                displayEmpty
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={groupID}
                                label="Group"
                                onChange={(e) => setGroupID(e.target.value)}
                            >
                                {groups.map((item) => {
                                    return <MenuItem value={item.value}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='primary' onClick={multiAssets}>Save</Button>
                        <Button variant='outlined' onClick={() => { setBatchDialog(false) }}>Cancel</Button>
                    </DialogActions>
                </Dialog> : <></>}
                {exportDialog ? <Dialog sx={{ flex: 1 }} open={exportDialog} onClose={handleCloseExportDataDialog}>
                    <DialogTitle>Download data for selected devices</DialogTitle>
                    <DialogContent className='downloadDataDialog'>
                        <FormControl size='small' sx={{ marginTop: 1, width: 350 }} fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                <div className="row">
                                    <TextField
                                        id="time-from"
                                        label="From Time"
                                        type="time"
                                        value={fromTime}
                                        onChange={handleFromTimeChange}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 150 }}
                                    />
                                    <DesktopDatePicker
                                        sx={{ marginBottom: 20 }}
                                        label="From Date" 
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                    />
                                </div>
                                <div className='row'>
                                    <TextField
                                        id="time-to"
                                        label="To Time"
                                        type="time"
                                        value={toTime}
                                        onChange={handleToTimeChange}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 150 }}
                                    />
                                    <DesktopDatePicker
                                        sx={{ marginBottom: 20 }}
                                        label="To Date"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                />
                                </div>
                            </Stack>
                        </LocalizationProvider>
                            
                        </FormControl>

                        { downloadLink === "" ? <></>
                            :   <p> The download should start automatically. If it doesn’t
                                    <a href={downloadLink} download>click here.</a>
                                </p>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='primary' onClick={downloadData}>Generate File</Button>
                        <Button variant='outlined' onClick={handleCloseExportDataDialog}>Close</Button>
                    </DialogActions>
                </Dialog> : <></>}
                <EditToolbar setRows={setRows} setRowModesModel={setRowModesModel} />
                {batch.length ? <Fab onClick={handleDeleteClick} sx={{ position: "fixed", right: 60, bottom: 8 }} size="small" color="primary" aria-label="delete"><DeleteIcon /></Fab> : <></>}
                {batch.length > 1 ? <Fab onClick={() => setBatchDialog(true)} sx={{ position: "fixed", right: 110, bottom: 8 }} size="small" color="primary" aria-label="edit"><EditIcon /></Fab> : <></>}
                {batch.length > 0 ? <IconButton className="downloadButton" variant="contained" onClick={() => setExportDialog(true)} sx={{ position: "fixed", right: batch.length > 1 ? 160: 110, bottom: 8 }} size="small" color="primary" aria-label="export"><FileDownload /> Download</IconButton> : <></>}
                <Snackbar open={snackBar ? true : false} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} onClose={() => { setSnackBar(false) }} message={snackBar} />
            </Box>
        </Box>
    );
}